import React from "react";
import Layout from "../components/layout";

const Cookie = () => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Cookie (süti) szabályzat</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            A sütikről általánosságban: a süti (cookie) egy kis fájl, amely
            akkor kerül a számítógépre, amikor Ön egy webhelyet látogat meg. A
            sütiknek rengeteg szerepe van, például információt gyűjtenek,
            megjegyzik a látogató egyéni beállításait. Általánosságban
            mondhatjuk, hogy megkönnyítik a weboldal használatát a felhasználók
            számára. A www.szelencecafe.hu oldal működéséhez cookie-kat
            használunk. A weboldalunk meglátogatása során, egy vagy több
            karaktersorozatból álló azonosító file („süti” vagy „cookie”) kerül
            elhelyezésre a Felhasználó számítógépén. A cookie-k segítségével a
            Felhasználó böngészője egyedileg azonosíthatóvá válik. A cookie-k
            használatát a Felhasználó a weblapon megjelenő felugró ablak
            segítségével hagyhatja jóvá.
          </p>
          <p>
            A használatot megkönnyítő sütik arra szolgálnak, hogy
            megjegyezhessük a weboldalunkkal kapcsolatos választásait.
          </p>
          <p>
            Hatékonyságot biztosító sütik. A www.szelencecafe.hu weboldalon a
            Google Analytics által használt cookie-k (sütik) is működnek, melyek
            működésére a Google adatvédelmi irányelvei vonatkoznak.{" "}
            <a href="https://support.google.com/analytics/answer/6004245?hl=hu">
              Részletek
            </a>
          </p>
          <p>
            A Google Analytics sütiket azzal kapcsolatban alkalmazzuk, hogy a
            látogatóink hogyan használják weboldalunkat. Ezek a sütik nem
            alkalmasak arra, hogy be tudják a látogatót személy szerint
            azonosítani (az aktuális IP címet is csak részben rögzítik), csak
            olyan tudnivalókat gyűjtenek, mint például hogy a látogató melyik
            oldalt nézte meg, a weboldal mely részére kattintott és mennyi
            oldalt keresett fel, valamint milyen hosszú volt az egyes
            munkamenetek megtekintési ideje. Ezeket a kapott információkat csak
            és kizárólag weboldalunk fejlesztésének, és a böngészők számára
            biztosított élmények javításának céljával használjuk.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <title>Szelence Café</title>;

export default Cookie;
